import React from 'react';
import classes from './Layout.scss';
import SipgateLogo from '../../media/images/sipgate_logo.svg';

type Props = {
	children: React.ReactNode;
};

const Layout = ({ children }: Props) => {
	return (
		<div className={classes.wrapper}>
			<img
				className={classes.sipgateLogo}
				src={SipgateLogo}
				alt="sipgate logo"
				aria-hidden="true"
			/>
			<main className={classes.content}>{children}</main>
		</div>
	);
};

export default Layout;
