import classnames from 'classnames';
import React from 'react';
import { Headline } from '../../../components/heading/Headline';
import { ContextMenu } from '../../../components/contextMenu/ContextMenu';
import { MenuItem } from '../../../components/contextMenu/MenuItem';

interface Props {
	/**
	 * Der Name der Seite.
	 */
	heading: string;

	/**
	 * Falls der Name der Seite sipgate Produkt-Branding haben soll
	 * (wie zum Beispiel **sipgate**.io), kann hier der zweite Teil
	 * des Namens angegeben werden.
	 *
	 * Falls beide Teile von einem Leerzeichen getrennt sein sollen,
	 * muss das `brandSuffix` mit diesem Leerzeichen beginnen.
	 */
	brandSuffix?: string;

	/**
	 * Ein Array von `ContextMenu`-Items.
	 */
	contextMenu?: { label: string; onClick: () => void; critical?: boolean }[];
}

const styles = {
	container: classnames('relative', 'bg-gray-75', 'border-b border-gray-100', 'py-[2.25rem]'),
	contextMenu: classnames('absolute', 'top-0', 'right-0', 'm-16'),
	heading: classnames('m-0', 'text-center', 'leading-32', 'text-2xl', 'font-bold', 'select-none'),
	brandSuffix: classnames('text-[1.05em]', 'font-light', 'font-meta'),
};

export const SideBarTextHeader = ({ heading, contextMenu, brandSuffix }: Props) => {
	return (
		<div className={styles.container}>
			<Headline className={styles.heading}>
				{heading}
				{brandSuffix ? <span className={styles.brandSuffix}>{brandSuffix}</span> : null}
			</Headline>

			<div className={styles.contextMenu}>
				{contextMenu && (
					<ContextMenu>
						{contextMenu.map(contextMenuItem => (
							<MenuItem
								key={contextMenuItem.label}
								onClick={contextMenuItem.onClick}
								critical={contextMenuItem.critical}
							>
								{contextMenuItem.label}
							</MenuItem>
						))}
					</ContextMenu>
				)}
			</div>
		</div>
	);
};
