import React from 'react';
import { PandaContext } from '@panda/ui';
import { translate, Translate } from '@web-apps/translations';
import { useAppSelector } from './hooks/redux';

interface Props {
	children: React.ReactNode;
}

const PandaProvider = (props: Props) => {
	const portalRoot = document.getElementById('overlay-portal')!;

	const translationsFetched = useAppSelector(state => state.translations.fetched);
	const translations = useAppSelector(state => state.translations.items);

	const getTranslation: Translate = (lk, ...replacements) => {
		if (translationsFetched) {
			return translate(translations)(lk, ...replacements);
		}

		return lk;
	};

	return (
		<PandaContext.Provider
			value={{
				languageKeys: {
					PANDA_FILEUPLOAD_INVALID_FILESIZE: getTranslation('PANDA_FILEUPLOAD_INVALID_FILESIZE'),
					PANDA_FILEUPLOAD_INVALID_FILETYPE: getTranslation('PANDA_FILEUPLOAD_INVALID_FILETYPE'),
					PANDA_CONTEXTMENU_LABEL: getTranslation('PANDA_CONTEXTMENU_LABEL'),
					PANDA_CONTEXTMENU_ARIA_LABEL: getTranslation('PANDA_CONTEXTMENU_ARIA_LABEL'),
					PANDA_LISTNAV_OF: getTranslation('PANDA_LISTNAV_OF'),
					PANDA_LISTNAV_COUNT: getTranslation('PANDA_LISTNAV_COUNT'),
					PANDA_LISTNAV_SORT_BY: getTranslation('PANDA_LISTNAV_SORT_BY'),
					PANDA_LISTNAV_TOTAL: getTranslation('PANDA_LISTNAV_TOTAL'),
					PANDA_LIST_VIEW_ACTIONS: getTranslation('PANDA_LIST_VIEW_ACTIONS'),
					PANDA_DETAIL_VIEW_SECTION_COUNT: getTranslation('PANDA_DETAIL_VIEW_SECTION_COUNT'),
					PANDA_DETAIL_VIEW_SECTION_TOTAL: getTranslation('PANDA_DETAIL_VIEW_SECTION_TOTAL'),
					PANDA_SEARCH_INPUT_CLEAR: getTranslation('PANDA_SEARCH_INPUT_CLEAR'),
					PANDA_SEARCH_INPUT_LABEL: getTranslation('PANDA_SEARCH_INPUT_LABEL'),
					PANDA_SEARCH_RESULTS: getTranslation('PANDA_SEARCH_RESULTS'),
					PANDA_SEARCH_NO_RESULTS_HEADING: getTranslation('PANDA_SEARCH_NO_RESULTS_HEADING'),
					PANDA_SEARCH_NO_RESULTS_PARAGRAPH: replacement =>
						getTranslation('PANDA_SEARCH_NO_RESULTS_PARAGRAPH', replacement),
					PANDA_SEARCH_NO_RESULTS_SUBHEADING: getTranslation('PANDA_SEARCH_NO_RESULTS_SUBHEADING'),
					PANDA_SEARCH_NO_RESULTS_LIST_ITEM_1: getTranslation(
						'PANDA_SEARCH_NO_RESULTS_LIST_ITEM_1'
					),
					PANDA_SEARCH_NO_RESULTS_LIST_ITEM_2: getTranslation(
						'PANDA_SEARCH_NO_RESULTS_LIST_ITEM_2'
					),
					PANDA_SEARCH_NO_RESULTS_LIST_ITEM_3: getTranslation(
						'PANDA_SEARCH_NO_RESULTS_LIST_ITEM_3'
					),
					PANDA_PAGINATION_OF_DIVIDER: getTranslation('PANDA_PAGINATION_OF_DIVIDER'),
					PANDA_PAGINATION_ENTRIES_PER_PAGE: getTranslation('PANDA_PAGINATION_ENTRIES_PER_PAGE'),
					PANDA_FILEUPLOAD_DROPOFFAREA_DROPOVER_TEXT: getTranslation(
						'PANDA_FILEUPLOAD_DROPOFFAREA_DROPOVER_TEXT'
					),
					PANDA_FILEUPLOAD_DROPOFFAREA_BUTTON_TEXT: getTranslation(
						'PANDA_FILEUPLOAD_DROPOFFAREA_BUTTON_TEXT'
					),
					PANDA_FILEUPLOAD_ARIA_DELETEFILE_PREFIX: getTranslation(
						'PANDA_FILEUPLOAD_ARIA_DELETEFILE_PREFIX'
					),
					PANDA_FILEUPLOAD_MAX_FILESIZE_LABEL: getTranslation(
						'PANDA_FILEUPLOAD_MAX_FILESIZE_LABEL'
					),
					PANDA_FILEUPLOAD_ALLOWED_FILETYPES_LABEL: getTranslation(
						'PANDA_FILEUPLOAD_ALLOWED_FILETYPES_LABEL'
					),
					PANDA_FILEUPLOAD_TOUCHDEVICE_BUTTON_LABEL: getTranslation(
						'PANDA_FILEUPLOAD_TOUCHDEVICE_BUTTON_LABEL'
					),
					PANDA_FILEUPLOAD_DROPOFFAREA_BUTTON_PREFIX: getTranslation(
						'PANDA_FILEUPLOAD_DROPOFFAREA_BUTTON_PREFIX'
					),
					PANDA_ICON_INTERNAL_LINK: getTranslation('PANDA_ICON_INTERNAL_LINK'),
					PANDA_ICON_COPY: getTranslation('PANDA_ICON_COPY'),
					PANDA_ICON_CLOSE: getTranslation('PANDA_ICON_CLOSE'),
					PANDA_ICON_DELETE: getTranslation('PANDA_ICON_DELETE'),
					PANDA_ICON_DOWNLOAD: getTranslation('PANDA_ICON_DOWNLOAD'),
					PANDA_ICON_EXTERNAL_LINK: getTranslation('PANDA_ICON_EXTERNAL_LINK'),
					PANDA_ICON_MAIL: getTranslation('PANDA_ICON_MAIL'),
					PANDA_ICON_PHONE: getTranslation('PANDA_ICON_PHONE'),
					PANDA_ICON_FILE: getTranslation('PANDA_ICON_FILE'),
					PANDA_ICON_INFO: getTranslation('PANDA_ICON_INFO'),
					PANDA_ICON_MORE: getTranslation('PANDA_ICON_MORE'),
					PANDA_ICON_ADD: getTranslation('PANDA_ICON_ADD'),
					PANDA_ICON_SEARCH: getTranslation('PANDA_ICON_SEARCH'),
					PANDA_ICON_EDIT: getTranslation('PANDA_ICON_EDIT'),
					PANDA_ICON_EXPORT: getTranslation('PANDA_ICON_EXPORT'),
					PANDA_ICON_BACK: getTranslation('PANDA_ICON_BACK'),
					PANDA_ICON_NEXT: getTranslation('PANDA_ICON_NEXT'),
					PANDA_ICON_DOWN: getTranslation('PANDA_ICON_DOWN'),
					PANDA_ICON_UP: getTranslation('PANDA_ICON_UP'),
					PANDA_ICON_SHOW_PASSWORD: getTranslation('PANDA_ICON_SHOW_PASSWORD'),
					PANDA_ICON_HIDE_PASSWORD: getTranslation('PANDA_ICON_HIDE_PASSWORD'),
					PANDA_SIDEBAR_NAVIGATION_LANDMARK: getTranslation('PANDA_SIDEBAR_NAVIGATION_LANDMARK'),
					PANDA_SIDEBAR_NAVIGATION_SKIP_TO_CONTENT_LINK: getTranslation(
						'PANDA_SIDEBAR_NAVIGATION_SKIP_TO_CONTENT_LINK'
					),
					PANDA_AVATAR_FROM: getTranslation('PANDA_AVATAR_FROM'),
					PANDA_AVATAR_REMAINING_COUNT: getTranslation('PANDA_AVATAR_REMAINING_COUNT'),
					PANDA_PASSWORD_INPUT_SHOWN: getTranslation('PANDA_PASSWORD_INPUT_SHOWN'),
					PANDA_PASSWORD_INPUT_HIDDEN: getTranslation('PANDA_PASSWORD_INPUT_HIDDEN'),
					PANDA_SELECT_PLACEHOLDER: getTranslation('PLACEHOLDER_SELECT_AN_OPTION'),
					PANDA_TITLE_SUFFIX: getTranslation('SIGNUP_TITLE_SUFFIX'),
					PANDA_INPUT_OPTIONAL: getTranslation('PANDA_INPUT_OPTIONAL'),
					PANDA_COMBOBOX_OVERFLOW: overflow => getTranslation('PANDA_COMBOBOX_OVERFLOW', overflow),
					PANDA_ADD_LOGO: getTranslation('PANDA_ADD_LOGO'),
					PANDA_CHANGE_LOGO: getTranslation('PANDA_CHANGE_LOGO'),
				},
				portalRoot,
			}}
		>
			{props.children}
		</PandaContext.Provider>
	);
};

export default PandaProvider;
