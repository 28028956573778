import React, { useId } from 'react';
import classnames from 'classnames';
import { RadioChildren } from './Radio';

import classes from './RadioItem.module.css';

type Props = {
	checked: boolean;
	name: string;
	value: string;
	children: RadioChildren;
	onChange: React.ChangeEventHandler<HTMLInputElement>;
	disabled?: boolean;
};

const styles = {
	labelContainer: (isDisabled: boolean, isString: boolean) =>
		classnames(
			'flex',
			'justify-start',
			'group',
			'block',
			'align-middle',
			isString ? 'items-start' : 'items-center',
			isDisabled ? 'cursor-not-allowed' : 'cursor-pointer'
		),
	touchTarget: classnames(
		'relative',
		'inline-flex',
		'shrink-0',
		'grow-0',
		'items-center',
		'justify-center',
		'appearance-none',
		'h-40',
		'pointer-fine:w-16',
		'pointer-coarse:w-40',
		'pointer-coarse:-mx-12',
		'rounded-none'
	),
	circle: (isDisabled: boolean, isChecked: boolean) =>
		classnames(
			'w-16',
			'h-16',
			'm-0',
			'bg-white',
			'rounded-full',
			/* An outline-none creates a glitch on click in the App-Web */
			'appearance-none',
			'transition-all',
			'duration-75',
			'ease-linear',
			isDisabled ? 'cursor-not-allowed' : 'cursor-pointer',
			isChecked &&
				!isDisabled && [
					'border-5',
					'border-blue-500',
					'focus-visible:ring-focus',
					'group-hover:bg-blue-100',
					'group-active:bg-blue-200',
				],
			isChecked && isDisabled && ['border-5', 'bg-gray-100', 'border-gray-400'],
			!isChecked &&
				!isDisabled && [
					'border-2',
					'border-gray-600',
					'focus-visible:ring-focus',
					'group-hover:border-blue-500',
					'group-active:border-blue-500',
					classes.backgroundActive,
					classes.backgroundHover,
				],
			!isChecked && isDisabled && ['border-2', 'bg-gray-100', 'border-gray-100']
		),
	label: (isDisabled: boolean, isString: boolean) =>
		classnames(
			'grow',
			'pl-8',
			'font-brand',
			'font-normal',
			'text-base/20',
			'select-none',
			isString && ['py-10'],
			isString && isDisabled && 'text-gray-400',
			isString && !isDisabled && 'text-gray-800',
			!isString && isDisabled && 'opacity-50'
		),
};

const RadioItem = ({
	checked = false,
	disabled = false,
	name,
	value,
	children,
	onChange,
}: Props): JSX.Element => {
	const id = useId();
	const childIsString = typeof children === 'string';

	return (
		<label
			className={styles.labelContainer(disabled, childIsString)}
			htmlFor={id}
			data-disabled={disabled}
		>
			<div className={styles.touchTarget}>
				<input
					className={classnames(styles.circle(disabled, checked))}
					type="radio"
					id={id}
					name={name}
					value={value}
					checked={checked}
					disabled={disabled}
					onChange={onChange}
				/>
			</div>
			{childIsString ? (
				<span className={styles.label(disabled, true)}>{children}</span>
			) : (
				<div className={styles.label(disabled, false)}>{children}</div>
			)}
		</label>
	);
};

export { RadioItem };
