import classnames from 'classnames';
import React, { useCallback, useId, useRef } from 'react';

interface Props {
	leftLabel: string;
	rightLabel: string;
	active: 'left' | 'right';
	onChange: (value: 'left' | 'right') => void;
	children: React.ReactNode;
}

const styles = {
	container: classnames(['w-full', 'min-w-288', 'max-w-320']),

	tablist: classnames(['relative', 'shrink-0', 'rounded', 'bg-gray-50', 'p-2', 'z-20', 'mb-8']),

	tab: classnames([
		'font-brand',
		'font-normal',
		'text-sm/16',
		'text-gray-800',
		'hover:text-gray-900',
		'active:text-black',
		'peer',
		'relative',
		'bg-transparent',
		'p-8',
		'w-1/2',
		'rounded',
		'cursor-pointer',
	]),

	rightTab: (active: boolean) =>
		classnames([
			'before:content-[""]',
			'before:absolute',
			'before:rounded',
			'before:w-full',
			'before:h-full',
			'before:left-0',
			'before:top-0',
			'before:z-[-1]',
			'before:bg-white',
			'before:transition-transform',
			'before:ease-in-out',
			'focus-visible:before:ring-focus-inset',
			'peer-focus-visible:before:ring-focus-inset',
			!active && 'before:translate-x-[-100%]',
			active && 'peer-hover:before:translate-x-[-1%]',
			active && 'peer-active:before:translate-x-[-0%]',
			!active && 'hover:before:translate-x-[-99%]',
			!active && 'active:before:translate-x-[-100%]',
		]),

	tabpanel: classnames(['grow', 'focus-visible:ring-focus']),

	label: classnames(['truncate', 'select-none']),
};

export const TabSwitch = ({ onChange, ...props }: Props) => {
	const leftTabId = useId();
	const rightTabId = useId();

	const leftTabRef = useRef<HTMLButtonElement>(null);
	const rightTabRef = useRef<HTMLButtonElement>(null);

	const leftPanelId = useId();
	const rightPanelId = useId();

	const onLeftClick = useCallback(() => onChange('left'), [onChange]);
	const onRightClick = useCallback(() => onChange('right'), [onChange]);

	const keyHandler = useCallback(
		(e: React.KeyboardEvent) => {
			if (e.key === 'ArrowLeft') {
				onChange('left');
				leftTabRef.current?.focus();
			}

			if (e.key === 'ArrowRight') {
				onChange('right');
				rightTabRef.current?.focus();
			}
		},
		[onChange]
	);

	return (
		<div className={styles.container}>
			{/* eslint-disable-next-line jsx-a11y/interactive-supports-focus */}
			<div role="tablist" className={styles.tablist} onKeyDown={keyHandler}>
				<button
					id={leftTabId}
					ref={leftTabRef}
					role="tab"
					onClick={onLeftClick}
					tabIndex={props.active === 'left' ? undefined : -1}
					className={styles.tab}
					aria-controls={leftPanelId}
					aria-selected={props.active === 'left' ? 'true' : 'false'}
					type="button"
				>
					<div className={classnames(styles.label)}>{props.leftLabel}</div>
				</button>
				<button
					id={rightTabId}
					ref={rightTabRef}
					role="tab"
					onClick={onRightClick}
					className={classnames(styles.tab, styles.rightTab(props.active === 'right'))}
					tabIndex={props.active === 'right' ? undefined : -1}
					aria-controls={rightPanelId}
					aria-selected={props.active === 'right' ? 'true' : 'false'}
					type="button"
				>
					<div className={classnames(styles.label)}>{props.rightLabel}</div>
				</button>
			</div>
			<div
				id={props.active === 'left' ? leftPanelId : rightPanelId}
				role="tabpanel"
				tabIndex={0}
				aria-labelledby={props.active === 'left' ? leftTabId : rightTabId}
				className={styles.tabpanel}
			>
				{props.children}
			</div>
		</div>
	);
};
