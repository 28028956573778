/* eslint-disable react/jsx-props-no-spreading */

import classnames from 'classnames';
import React, { forwardRef, memo } from 'react';
import { PandaIcon } from '../../assets/icons/panda-icons/PandaIcon';
import { DisabledContext, isDisabled as isContextDisabled } from '../../contexts/disabledContext';

type Props = Omit<
	React.InputHTMLAttributes<HTMLInputElement>,
	'type' | 'value' | 'className' | 'onChange' | 'checked'
> & {
	// eslint-disable-next-line react/no-unused-prop-types
	onChange?: (checked: boolean) => void;
	// eslint-disable-next-line react/no-unused-prop-types
	checked: boolean | 'indeterminate';
};

const styles = {
	div: (checked: boolean | 'indeterminate', isDisabled: boolean) =>
		classnames(
			'group',
			'relative',
			'z-0',
			'h-40',
			'pointer-fine:w-16',
			'pointer-fine:py-12',
			'pointer-coarse:w-40',
			'pointer-coarse:p-12',
			'pointer-coarse:-mx-12',
			'leading-none',
			isDisabled ? 'cursor-not-allowed' : 'cursor-pointer'
		),
	check: (checked: boolean | 'indeterminate', isDisabled: boolean) =>
		classnames(
			'absolute',
			'transition',
			'ease-in-out',
			'duration-100',
			'rounded',
			'z-10',
			'pointer-events-none',
			!isDisabled && [
				'group-hover:pointer-fine:bg-blue-100',
				'group-active:pointer-fine:bg-blue-200',
			],
			checked && !isDisabled && ['bg-white'],
			checked && isDisabled && ['bg-gray-100'],
			!checked && !isDisabled && ['bg-white'],
			!checked && isDisabled && ['bg-gray-100']
		),
	dash: (isDisabled: boolean) =>
		classnames(
			'absolute',
			'transition-colors',
			'ease-in-out',
			'duration-100',
			'rounded',
			'z-10',
			'pointer-events-none',
			!isDisabled && ['bg-blue-500'],
			isDisabled && ['bg-gray-400']
		),
	input: (checked: boolean | 'indeterminate', isDisabled: boolean) =>
		classnames(
			'w-16',
			'h-16',
			'm-0',
			'relative',
			/* An outline-none creates a glitch on click in the App-Web */
			'appearance-none',
			'transition-background',
			'ease-in-out',
			'duration-100',
			'rounded',
			'border-2',
			'z-0',
			checked && !isDisabled && ['border-blue-500', 'focus-visible:ring-focus'],
			checked && isDisabled && ['border-gray-400'],
			!checked &&
				!isDisabled && [
					'border-gray-600',
					'group-hover:border-blue-500',
					'group-active:border-blue-500',
					'focus-visible:ring-focus',
				],
			!checked && isDisabled && ['bg-gray-100', 'border-gray-100'],
			isDisabled ? 'cursor-not-allowed' : 'cursor-pointer',

			checked === true && !isDisabled && ['bg-blue-500'],
			checked === true && isDisabled && ['bg-gray-400', 'border-gray-400'],
			!checked && !isDisabled && ['bg-white'],

			checked === 'indeterminate' &&
				!isDisabled && ['group-hover:bg-blue-100', 'group-active:bg-blue-200'],
			checked === 'indeterminate' && isDisabled && ['bg-gray-100', 'border-gray-100']
		),
};

export const VisualCheckbox = memo(
	forwardRef<HTMLInputElement, Props>(
		({ checked = false, disabled, onChange, ...inputProps }: Props, ref): JSX.Element => {
			const disabledContextValue = React.useContext(DisabledContext);
			const isDisabled = isContextDisabled(disabled, disabledContextValue);
			const getMark = () => {
				if (checked === 'indeterminate') {
					return <PandaIcon icon="dash-16" className={styles.dash(isDisabled)} />;
				}

				return <PandaIcon icon="check-16" className={styles.check(checked, isDisabled)} />;
			};

			/* eslint-disable jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */
			return (
				<div
					className={styles.div(checked, isDisabled)}
					onClick={e => {
						if (!isDisabled && onChange && e.target === e.currentTarget) {
							onChange(!checked);
						}
					}}
				>
					{getMark()}
					<input
						ref={ref}
						className={styles.input(checked, isDisabled)}
						type="checkbox"
						disabled={isDisabled}
						checked={checked === 'indeterminate' ? false : checked}
						aria-checked={checked === 'indeterminate' ? 'mixed' : checked}
						onChange={() => {
							if (onChange) {
								onChange(!checked);
							}
						}}
						{...inputProps}
					/>
				</div>
			);
		}
	)
);
