import classnames from 'classnames';
import React from 'react';
import { assertChildTypes } from '@web-apps/react-utils';
import { SideBarEntityHeader } from './headers/SideBarEntityHeader';
import { SideBarNavigation } from './SideBarNavigation';

import { HeadingBoundary } from '../../components/heading/HeadingBoundary';
import { SideBarLogoHeader } from './headers/SideBarLogoHeader';
import { SideBarTextHeader } from './headers/SideBarTextHeader';

export type Props = {
	/**
	 * A rendered `SideBarEntityHeader` or `SideBarLogoHeader`.
	 */
	header: React.ReactNode;

	/**
	 * Zusätzliche Informationen, wie zum Beispiel eine Mail Adresse, die unter der Überschrift angezeigt werden.
	 */
	metadata?: React.ReactNode;

	/**
	 * Navigationseinträge, die unter dem Profil dargestellt werden sollen.
	 * Eine Navigation zu externen Seiten wird nicht unterstützt.
	 */
	navigation?: { label: string; url: string }[];

	/**
	 * Ein Platzhalter um eure Widgets anzeigen zu können.
	 */
	widget?: React.ReactNode;
};

const styles = {
	header: classnames('mb-16'),
	metadata: classnames('text-gray-600', 'mt-16', 'mx-16', 'text-center', 'text-sm/20'),
	container: classnames('overflow-hidden', 'bg-white', 'rounded-lg', 'border', 'border-gray-100'),
};

export const SideBarView = ({ header, navigation, metadata, widget }: Props) => {
	assertChildTypes(header, [SideBarEntityHeader, SideBarLogoHeader, SideBarTextHeader]);

	return (
		<>
			<section className={styles.container}>
				<header className={styles.header}>
					{header}

					{metadata ? <div className={styles.metadata}>{metadata}</div> : null}
				</header>

				{navigation ? <SideBarNavigation items={navigation} /> : null}
			</section>
			<HeadingBoundary>{widget}</HeadingBoundary>
		</>
	);
};
