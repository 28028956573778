import classNames from 'classnames';
import React, { AriaAttributes, useEffect, useRef } from 'react';
import { PandaIcon } from '../../assets/icons/panda-icons/PandaIcon';
import { useDisabled } from '../../contexts/disabledContext';
import { usePandaContext } from '../../contexts/pandaContext';
import { useAriaId } from '../../hooks/useAriaId';
import { Description } from '../visualComponents/Description';
import { ErrorDescription } from '../visualComponents/ErrorDescription';
import { Label } from '../visualComponents/Label';
import { VisualInput } from '../visualComponents/VisualInput';

type Props = {
	name: string | undefined;
	value: string;
	onChange: React.ChangeEventHandler<HTMLInputElement>;
	onBlur: React.FocusEventHandler<HTMLInputElement> | undefined;
	onFocus: React.FocusEventHandler<HTMLInputElement> | undefined;
	error: string | undefined;
	label: string | undefined;
	placeholder: string | undefined;
	maxLength: number | undefined;
	description: string | undefined;
	autoFocus: boolean;
	disabled: boolean | undefined;
	readOnly: boolean;
	optional: boolean | undefined;
	type: string;
	min: Date | number | undefined;
	max: Date | number | undefined;
	inputMode: 'text' | 'tel' | 'url' | 'email' | 'numeric' | 'decimal' | 'search' | undefined;
	autocomplete: string | undefined;
} & AriaAttributes;

const styles = {
	div: classNames('group', 'w-full', 'flex', 'flex-wrap'),
	optional: classNames(
		'mb-4',
		'mx-2',
		'font-brand',
		'font-light',
		'text-xs/14',
		'text-gray-600',
		'float-right',
		'select-none'
	),
	inputContainer: classNames('relative', 'w-full'),
	icon: classNames('absolute', 'right-12', 'inset-y-0', 'h-40'),
};

function formatMinMax(value: Date | number | undefined) {
	if (value === undefined) {
		return undefined;
	}

	if (typeof value === 'number') {
		return value.toString(10);
	}

	// We intentionally pass the date in local time because the user
	// will provide a date in their local time zone.
	return `${value.getFullYear().toString().padStart(4, '0')}-${(value.getMonth() + 1)
		.toString()
		.padStart(2, '0')}-${value.getDate().toString().padStart(2, '0')}`;
}

export const RawInput = ({
	name,
	error,
	value,
	onChange,
	onBlur,
	onFocus,
	autoFocus,
	description,
	label,
	placeholder,
	disabled,
	readOnly,
	optional,
	type,
	min,
	max,
	maxLength,
	inputMode: rawInputMode,
	autocomplete,
	...ariaAttributes
}: Props) => {
	const inputElement = useRef<HTMLInputElement>(null);
	const isDisabled = useDisabled(disabled);
	const id = useAriaId('textinput');
	const { languageKeys } = usePandaContext();

	useEffect(() => {
		if (inputElement.current && autoFocus) {
			inputElement.current.focus();
		}
	}, [autoFocus]);

	const focusInput = () => {
		inputElement.current?.focus?.();
	};

	const getCondition = () => {
		if (isDisabled || readOnly) {
			return 'disabled';
		}

		if (typeof error !== 'undefined') {
			return 'error';
		}

		return 'default';
	};

	const inputMode = rawInputMode || (type === 'number' ? 'numeric' : undefined);

	return (
		<div className={styles.div}>
			{label ? <Label htmlFor={id}>{label}</Label> : null}
			{optional ? (
				<span className={styles.optional}>{languageKeys.PANDA_INPUT_OPTIONAL}</span>
			) : null}

			{description ? (
				<Description id={`${id}-description`} onClick={focusInput}>
					{description}
				</Description>
			) : null}

			<div className={styles.inputContainer}>
				{error ? <PandaIcon icon="exclamation_mark_circle-16" className={styles.icon} /> : null}
				<VisualInput
					condition={getCondition()}
					ref={inputElement}
					id={id}
					disabled={isDisabled}
					readOnly={readOnly}
					maxLength={maxLength}
					name={name}
					placeholder={placeholder}
					value={value}
					onChange={onChange}
					onBlur={onBlur}
					onFocus={onFocus}
					type={type}
					min={formatMinMax(min)}
					max={formatMinMax(max)}
					inputMode={inputMode}
					pattern={inputMode === 'numeric' ? '[0-9]*' : undefined}
					/* eslint-disable-next-line react/jsx-props-no-spreading */
					{...ariaAttributes}
					aria-describedby={description && `${id}-description`}
					aria-invalid={Boolean(error)}
					autoComplete={autocomplete}
				/>
			</div>

			{error ? <ErrorDescription onClick={focusInput}>{error}</ErrorDescription> : null}
		</div>
	);
};
