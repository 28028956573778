/* eslint-disable @web-apps/no-custom-colors */

export const getInitials = (firstname: string, lastname: string) => {
	return (firstname.substring(0, 1) + lastname.substring(0, 1)).toLocaleUpperCase();
};

const hashCode = (s: string) => {
	let h = 0;
	for (let i = 0; i < s.length; i += 1) {
		// eslint-disable-next-line no-bitwise
		h = (Math.imul(31, h) + s.charCodeAt(i)) | 0;
	}

	return Math.abs(h);
};

export const getColor = (firstname: string, lastname: string, email?: string) => {
	const colors = [
		'#0042A3',
		'#007BE0',
		'#008E47',
		'#00AE57',
		'#00CEA6',
		'#057C76',
		'#09AD99',
		'#0DC96B',
		'#1086FC',
		'#1EE4C0',
		'#27DC82',
		'#603DFF',
		'#6EC1FF',
		'#8064FF',
		'#8F001A',
		'#A693FC',
		'#A8168C',
		'#AD0020',
		'#BFB1FA',
		'#C90C2F',
		'#D746B9',
		'#DB2742',
		'#F76F07',
		'#FD8114',
		'#FF64DC',
		'#FF91E1',
		'#FF952B',
		'#FFA64D',
	];

	const hash = hashCode(`${firstname}${lastname}${email || ''}`);
	const index = Math.min(Math.max(hash % colors.length, 0), colors.length - 1);

	return colors[index];
};
