import React, { useId } from 'react';
import classnames from 'classnames';

import classes from './RadioCardItem.module.css';

type Props = {
	checked: boolean;
	name: string;
	value: string;
	label: string;
	disabled?: boolean;
	description?: string;
	additional: React.ReactNode;
	onChange: React.ChangeEventHandler<HTMLInputElement>;
};

const styles = {
	cardContainer: classnames(
		'group',
		'flex',
		'w-full',
		'p-16',
		'mb-8',
		'last:mb-0',
		'bg-white',
		'min-h-72',
		'justify-start',
		'items-center',
		'gap-12',
		'rounded-lg',
		'ring-inset',
		'cursor-pointer',
		'ring-1',
		'ring-gray-100',
		'font-brand',
		'hover:ring-blue-300',
		'active:ring-blue-400',
		'has-[:focus-visible]:ring',
		'has-[:focus-visible]:ring-color-focus',
		'has-[:checked]:ring-blue-500',
		'has-[:checked]:ring-2',
		'has-[:checked]:hover:ring-blue-500',
		'has-[:checked]:active:ring-blue-500',
		'has-[:disabled]:cursor-not-allowed',
		'has-[:disabled]:hover:ring-gray-100',
		'has-[:disabled]:active:ring-gray-100',
		'has-[:checked:disabled]:ring-1',
		'has-[:checked:disabled]:ring-gray-100',
		'has-[:checked:disabled]:bg-gray-100',
		'has-[:checked:disabled]:hover:ring-1',
		'has-[:checked:disabled]:hover:ring-gray-100',
		'has-[:checked:disabled]:hover:bg-gray-100'
	),
	touchTarget: classnames(
		'relative',
		'inline-flex',
		'shrink-0',
		'grow-0',
		'items-center',
		'justify-center',
		'appearance-none',
		'pointer-fine:w-16',
		'pointer-coarse:w-40',
		'pointer-coarse:-mx-12',
		'rounded-none'
	),
	circle: classnames(
		'w-16',
		'h-16',
		'm-0',
		'bg-white',
		'rounded-full',
		/* An outline-none creates a glitch on click in the App-Web */
		'appearance-none',
		'transition-all',
		'duration-75',
		'ease-linear',
		'cursor-pointer',
		'border-2',
		'border-gray-600',
		'group-hover:border-blue-500',
		'group-active:border-blue-500',
		classes.backgroundActive,
		classes.backgroundHover,
		'disabled:cursor-not-allowed',
		'checked:border-5',
		'checked:border-blue-500',
		'checked:disabled:border-gray-400',
		'disabled:border-gray-100',
		'disabled:bg-gray-100',
		'group-hover:checked:bg-blue-100',
		'group-active:checked:bg-blue-200',
		'group-hover:disabled:bg-gray-100',
		'group-active:disabled:bg-gray-100',
		'group-hover:disabled:border-gray-100',
		'group-active:disabled:border-gray-100',
		'group-hover:disabled:checked:border-gray-400',
		'group-active:disabled:checked:border-gray-400'
	),
	content: classnames('grow', 'select-none'),
	label: classnames(
		'text-base/20',
		'font-bold',
		'text-gray-800',
		'group-has-[:disabled]:text-gray-400'
	),
	description: classnames('text-xs/16', 'text-gray-600', 'group-has-[:disabled]:text-gray-400'),
	additional: classnames(
		'text-gray-600',
		'font-normal',
		'w-40',
		'h-40',
		'flex',
		'select-none',
		classes.additional
	),
};

const RadioCardItem = ({
	checked = false,
	disabled = false,
	name,
	label,
	value,
	onChange,
	description,
	additional,
}: Props): JSX.Element => {
	const id = useId();

	return (
		<label className={classnames(styles.cardContainer)}>
			<div className={styles.touchTarget}>
				<input
					className={classnames(styles.circle)}
					type="radio"
					id={id}
					name={name}
					value={value}
					checked={checked}
					disabled={disabled}
					onChange={onChange}
				/>
			</div>
			<div className={styles.content}>
				<span className={styles.label}>{label}</span>
				<div className={styles.description}>{description}</div>
			</div>

			{additional &&
				(description ? (
					<div className={`${styles.additional} justify-end items-start`}>{additional}</div>
				) : (
					<div className={`${styles.additional} justify-center items-center`}>{additional}</div>
				))}
		</label>
	);
};

export { RadioCardItem };
