import React, { AriaAttributes } from 'react';
import classnames from 'classnames';
import { ContextMenu } from '../components/contextMenu/ContextMenu';
import { MenuItem } from '../components/contextMenu/MenuItem';
import classes from './ListItem.module.css';

type ContextMenuEntry = {
	label: string;
	onClick: () => void;
	critical?: boolean;
};

type Props = {
	/**
	 * Die einzelnen ListViewColumn Einträge.
	 */
	children: React.ReactNode;
	/**
	 * Ein Array von `ContextMenu`-Items
	 *
	 * **ContextMenuEntry**:
	 *
	 * ```
	 * {
	 *  label: string;
	 *  onClick: () => void;
	 *  critical?: boolean;
	 * }
	 * ```
	 */
	contextMenu: (ContextMenuEntry | null)[];
	showShadow?: boolean;
} & Pick<AriaAttributes, 'aria-posinset' | 'aria-setsize'>;

const styles = {
	row: classnames('bg-white', 'h-80', 'text-gray-800', 'text-lg/24', 'whitespace-nowrap'),
	background: classnames([
		'p-0',
		'sticky',
		'right-0',
		'bg-gradient-to-r',
		'from-transparent',
		'to-gray-25',
	]),
	menu: classnames([
		'flex',
		'justify-end',
		'border-gray-100',
		'border-y-2',
		'border-e-2',
		'rounded-y-lg',
		'rounded-e-lg',
		'overflow-hidden',
	]),
	shadow: (showShadow: boolean) =>
		classnames([
			showShadow ? 'before:opacity-100' : 'before:opacity-0',
			'flex',
			'justify-center',
			'items-center',
			'relative',
			'bg-white',
			'h-[4.75rem]',
			'w-[4.75rem]',
			"before:content-['']",
			'before:h-full',
			'before:w-full',
			'before:absolute',
			'before:block',
			'before:top-0',
			'before:left-0',
			'before:shadow',
			'before:transition-opacity',
		]),
	firstLineOverflow: classnames([
		classes.horribleCornerHack,
		'sticky',
		'left-0',
		'p-0',
		"after:content-['']",
		'after:h-full',
		'after:w-full',
		'after:block',
		'after:top-0',
		'after:absolute',
		'after:border-gray-100',
		'after:border-s-2',
		'after:border-y-2',
		'after:rounded-y-lg',
		'after:rounded-s-lg',
		'after:bg-gradient-to-r',
		'after:from-white',
	]),
};

export const ListItem = ({
	children,
	contextMenu,
	showShadow = false,
	...ariaAttributes
}: Props) => {
	return (
		<tr
			className={styles.row}
			aria-posinset={ariaAttributes['aria-posinset']}
			aria-setsize={ariaAttributes['aria-setsize']}
		>
			<td aria-hidden className={styles.firstLineOverflow} />

			{children}

			<td className={styles.background}>
				<div className={styles.menu}>
					<div className={styles.shadow(showShadow)}>
						<ContextMenu>
							{contextMenu
								.filter((e): e is ContextMenuEntry => e !== null)
								.map(({ label, onClick, critical }) => (
									<MenuItem onClick={onClick} critical={critical} key={label}>
										{label}
									</MenuItem>
								))}
						</ContextMenu>
					</div>
				</div>
			</td>
		</tr>
	);
};
