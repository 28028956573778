import React, { ComponentProps } from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { ListNavViewItem } from './ListNavViewItem';
import { ListNavViewColumn } from './ListNavViewColumn';
import { ColumnMeta } from './types';
import { SearchNoResult } from '../../components/search/SearchNoResult';
import { StatePropsProvider } from '../../components/state/State';
import { SetIcon } from '../../assets/icons/icon-set/SetIcon';

export type ListProps<T, Columns extends Record<string, ColumnMeta<T>>> = {
	items: T[];
	children: (item: T) => JSX.Element;
	columns: Columns;
	searchTerm: string;
};

const styles = {
	list: classnames('list-none', 'pl-0', 'm-0', 'font-brand'),
	link: classnames(
		'group',
		'flex',
		'items-center',
		'h-64',
		'w-full',
		'my-8',
		'px-16',
		'py-8',
		'bg-white',
		'rounded-lg',
		'no-underline',
		'ring-2',
		'ring-inset',
		'ring-gray-100',
		'hover:ring-blue-600',
		'hover:bg-blue-25',
		'focus:outline-none',
		'focus-visible:ring-focus',
		'active:bg-blue-50',
		'active:ring-blue-700',
		'text-gray-800',
		'visited:text-gray-800',
		'duration-150',
		'ease-in-out',
		'transition'
	),
	graphic: classnames('w-40', 'h-40', 'flex', 'items-center', 'justify-center', 'mr-8', '-ml-4'),
	contentContainer: classnames('flex', 'flex-col', 'grow', 'justify-start', 'overflow-hidden'),
	nameContainer: classnames('flex', 'flex-row'),
	name: classnames(
		'text-base/20',
		'text-gray-800',
		'font-bold',
		'group-hover:text-blue-800',
		'group-focus-visible:text-gray-800',
		'group-active:text-blue-900',
		'truncate',
		'duration-150',
		'ease-in-out',
		'transition-colors'
	),
	description: classnames(
		'text-base/20',
		'text-gray-800',
		'font-light',
		'group-hover:text-blue-800',
		'group-focus-visible:text-gray-800',
		'group-active:text-blue-900',
		'truncate',
		'duration-150',
		'ease-in-out',
		'transition-colors'
	),
	chevronContainer: classnames('flex', 'flex-none', 'items-center', 'justify-end', 'pl-8'),
	chevronIcon: classnames(
		'bg-gray-800',
		'group-hover:bg-blue-800',
		'group-active:bg-blue-900',
		'duration-150',
		'ease-in-out',
		'transition-color',
		'pointer-events-none',
		'duration-150',
		'ease-in-out'
	),
	noResultsContainer: classnames('p-16'),
	stateContainer: classnames('inline-flex', 'pr-6'),
};

const renderColumnContent = (columnContent: React.ReactNode) => {
	if (Array.isArray(columnContent) && columnContent.length === 1) {
		return columnContent[0];
	}

	if (Array.isArray(columnContent) && columnContent.length > 1) {
		return (
			<>
				{columnContent[0]}, {columnContent[1]}
			</>
		);
	}
	return columnContent;
};

export const ListNavAsList = <T, Columns extends Record<string, ColumnMeta<T>>>({
	items,
	children: mapItemsToChildren,
	columns,
	searchTerm,
}: ListProps<T, Columns>) => {
	const renderListItem = (item: React.ReactComponentElement<typeof ListNavViewItem>) => {
		const columnArray = React.Children.toArray(item.props.children)
			.map(c => c as React.ReactComponentElement<typeof ListNavViewColumn>)
			.map(c => ({
				props: c.props,
				meta: columns[c.props.columnKey] as ColumnMeta<T> | undefined,
			}))
			.filter(
				(c): c is { props: ComponentProps<typeof ListNavViewColumn>; meta: ColumnMeta<T> } =>
					!!c.meta
			)
			.filter(c => !c.meta.hidden);

		const stateColumn = columnArray.find(c => c.meta.isState);
		const [firstColumn, secondColumn] = columnArray.filter(c => !c.meta.isState);

		const renderDescription = () => {
			if (!secondColumn) {
				return null;
			}

			const columnTitle = secondColumn.meta.text;
			const columnContent = secondColumn.props.children;

			if (
				columnContent === null ||
				columnContent === '' ||
				(Array.isArray(columnContent) && columnContent.length === 0)
			) {
				return (
					<div className={styles.description}>
						<span>{columnTitle}: </span>
						<span aria-hidden>–</span>
					</div>
				);
			}

			return (
				<div className={styles.description}>
					<span>{columnTitle}: </span>
					{renderColumnContent(columnContent)}
				</div>
			);
		};

		return (
			<li key={item.key}>
				<Link className={styles.link} to={item.props.href}>
					{item.props.graphic ? (
						<div className={styles.graphic}>{item.props.graphic.large}</div>
					) : null}
					<div className={styles.contentContainer}>
						<div className={styles.nameContainer}>
							<StatePropsProvider size="xsmaller">
								{stateColumn ? (
									<div className={styles.stateContainer}>{stateColumn.props.children}</div>
								) : null}
							</StatePropsProvider>
							<div className={styles.name}>{firstColumn.props.children}</div>
						</div>
						{renderDescription()}
					</div>
					<div className={styles.chevronContainer}>
						<SetIcon icon="next" size="24" className={styles.chevronIcon} />
					</div>
				</Link>
			</li>
		);
	};

	if (!items.length) {
		return (
			<div className={styles.noResultsContainer}>
				<SearchNoResult searchTerm={searchTerm} />
			</div>
		);
	}

	return (
		<ul className={styles.list}>{items.map(item => renderListItem(mapItemsToChildren(item)))}</ul>
	);
};
