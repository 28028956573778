import React from 'react';
import { assertChildTypes, flattenChildren, isChildType } from '@web-apps/react-utils';
import classnames from 'classnames';
import { DisabledContext, isDisabled } from '../../contexts/disabledContext';
import { RadioItem } from './RadioItem';
import { Radio } from './Radio';
import { RadioCard } from './RadioCard';
import { RadioCardItem } from './RadioCardItem';

type Props = {
	onChange: React.ChangeEventHandler<HTMLInputElement>;
	/**
	 * Die `value`-Prop beschreibt das ausgewählte `Radio` und
	 * entspricht seiner `value`-Prop.
	 */
	value: string;
	/**
	 * Setzt das HTML-Attribut `name` für die `Radio`s
	 */
	name: string;
} & AdditionalProps;

type ManagedProps = {
	/**
	 * Die Prop `managedField` kann genutzt werden, um die renderProps der
	 * [`ManagedForm`-Komponente](https://github.com/sipgate/web-apps/blob/main/shared/forms/Readme.md)
	 * entgegenzunehmen.
	 *
	 */
	managedField: {
		name: string;
		value: string;
		onChange: React.ChangeEventHandler<HTMLInputElement>;
		onBlur: React.FocusEventHandler<HTMLInputElement>;
	} & ({ valid: true; error: null } | { valid: false; error: string });
} & AdditionalProps;

type AdditionalProps = {
	children: React.ReactNode;
	/**
	 * Die `title`-Prop ist die Überschrift der `RadioGroup`.
	 */
	title: string;
	disabled?: boolean;
};

const styles = {
	fieldset: 'font-brand',
	title: classnames(
		'pl-0',
		'text-gray-600',
		'font-brand',
		'font-normal',
		'text-xs/14',
		'cursor-default',
		'mb-4',
		'select-none'
	),
};

const RadioGroup = ({
	children,
	onChange,
	value,
	name,
	title,
	disabled: isGroupDisabled,
}: Props): JSX.Element => {
	const isContextDisabled = React.useContext(DisabledContext);

	assertChildTypes(children, [Radio, RadioCard]);

	return (
		<fieldset className={styles.fieldset}>
			<legend className={styles.title}>{title}</legend>
			{flattenChildren(children).map(item => {
				if (isChildType(item, Radio)) {
					return (
						<RadioItem
							onChange={onChange}
							name={name}
							key={item.props.value}
							value={item.props.value}
							disabled={isDisabled(item.props.disabled || isGroupDisabled, isContextDisabled)}
							checked={value === item.props.value}
						>
							{item.props.children}
						</RadioItem>
					);
				}

				if (isChildType(item, RadioCard)) {
					return (
						<RadioCardItem
							onChange={onChange}
							name={name}
							key={item.props.value}
							value={item.props.value}
							disabled={isDisabled(item.props.disabled || isGroupDisabled, isContextDisabled)}
							checked={value === item.props.value}
							label={item.props.label}
							description={item.props.description}
							additional={item.props.additional}
						/>
					);
				}

				return null;
			})}
		</fieldset>
	);
};

const ManagedRadioGroup = ({
	managedField: { name, value, onChange },
	children,
	...otherProps
}: ManagedProps) => (
	<RadioGroup
		// eslint-disable-next-line react/jsx-props-no-spreading
		{...otherProps}
		name={name}
		value={value}
		onChange={onChange}
	>
		{children}
	</RadioGroup>
);

export { RadioGroup, ManagedRadioGroup };
