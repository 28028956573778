import classnames from 'classnames';
import React from 'react';

type Props = Omit<React.HTMLAttributes<HTMLParagraphElement>, 'className'>;

const styles = {
	description: classnames(
		'w-full',
		'block',
		'mt-0',
		'mb-4',
		'mx-2',
		'text-gray-600',
		'font-brand',
		'font-light',
		'text-xs/14',
		'cursor-default'
	),
};

const Description = ({ children, ...paragraphProps }: Props): JSX.Element => {
	return (
		// eslint-disable-next-line react/jsx-props-no-spreading
		<p {...paragraphProps} className={styles.description}>
			{children}
		</p>
	);
};

export { Description };
