import classnames from 'classnames';
import React, { useId } from 'react';
import { StateColor } from '../state/State';
import classes from './VisualAvatar.module.css';

import redIcon8 from '../state/icons/red-8.svg';
import redIcon10 from '../state/icons/red-10.svg';
import redIcon12 from '../state/icons/red-12.svg';
import redIcon16 from '../state/icons/red-16.svg';
import redIcon24 from '../state/icons/red-24.svg';

import orangeIcon8 from '../state/icons/orange-8.svg';
import orangeIcon10 from '../state/icons/orange-10.svg';
import orangeIcon12 from '../state/icons/orange-12.svg';
import orangeIcon16 from '../state/icons/orange-16.svg';
import orangeIcon24 from '../state/icons/orange-24.svg';

import greenIcon8 from '../state/icons/green-8.svg';
import greenIcon10 from '../state/icons/green-10.svg';
import greenIcon12 from '../state/icons/green-12.svg';
import greenIcon16 from '../state/icons/green-16.svg';
import greenIcon24 from '../state/icons/green-24.svg';

import blueIcon8 from '../state/icons/blue-8.svg';
import blueIcon10 from '../state/icons/blue-10.svg';
import blueIcon12 from '../state/icons/blue-12.svg';
import blueIcon16 from '../state/icons/blue-16.svg';
import blueIcon24 from '../state/icons/blue-24.svg';

export type Size = 'small' | 'medium' | 'large' | 'xlarge' | 'biggest';

type Props = {
	size: Size;
	text: string;
	imageUrl?: string;
	imageSrcSet?: string;

	title: string;
	state?: StateColor;
	ariaLabel?: string;

	textColor: string;
	backgroundColor: string;

	isOverlapped?: boolean;
	isRemainingCount?: boolean;
};

const getClassForSize = (avatarSize: Size) => {
	switch (avatarSize) {
		case 'small':
			return classes.small;
		case 'medium':
			return classes.medium;
		case 'large':
			return classes.large;
		case 'xlarge':
			return classes.xlarge;
		case 'biggest':
			return classes.biggest;
		default:
			return classes.medium;
	}
};

const getRedIcon = (size: Size) => {
	switch (size) {
		case 'small':
			return redIcon8;
		case 'medium':
			return redIcon10;
		case 'large':
			return redIcon12;
		case 'xlarge':
			return redIcon16;
		case 'biggest':
			return redIcon24;
	}
};

const getOrangeIcon = (size: Size) => {
	switch (size) {
		case 'small':
			return orangeIcon8;
		case 'medium':
			return orangeIcon10;
		case 'large':
			return orangeIcon12;
		case 'xlarge':
			return orangeIcon16;
		case 'biggest':
			return orangeIcon24;
	}
};

const getGreenIcon = (size: Size) => {
	switch (size) {
		case 'small':
			return greenIcon8;
		case 'medium':
			return greenIcon10;
		case 'large':
			return greenIcon12;
		case 'xlarge':
			return greenIcon16;
		case 'biggest':
			return greenIcon24;
	}
};

const getBlueIcon = (size: Size) => {
	switch (size) {
		case 'small':
			return blueIcon8;
		case 'medium':
			return blueIcon10;
		case 'large':
			return blueIcon12;
		case 'xlarge':
			return blueIcon16;
		case 'biggest':
			return blueIcon24;
	}
};

const getStateIcon = (size: Size, color: StateColor) => {
	switch (color) {
		case 'red':
			return getRedIcon(size);
		case 'orange':
			return getOrangeIcon(size);
		case 'green':
			return getGreenIcon(size);
		case 'blue':
			return getBlueIcon(size);
	}
};

const renderState = (size: Size, color?: StateColor) => {
	if (!color) {
		return null;
	}

	return (
		<foreignObject x="0" y="0" width="100%" height="100%">
			<img alt="" src={getStateIcon(size, color)} className={classes.state} />
		</foreignObject>
	);
};

export const VisualAvatar = ({
	text,
	title,
	ariaLabel,
	backgroundColor,
	textColor,
	state,
	size,
	imageUrl,
	imageSrcSet,
	isOverlapped = false,
	isRemainingCount = false,
}: Props): JSX.Element => {
	const maskId = useId();
	const [loadFailed, setLoadFailed] = React.useState(false);

	return (
		<svg
			role="img"
			aria-label={ariaLabel}
			className={classnames(classes.wrapper, getClassForSize(size))}
		>
			<title>{title}</title>

			<mask id={maskId}>
				<circle className={classes.visibleMask} fill="white" />

				{state ? <circle className={classes.stateMask} fill="black" /> : null}
				{isOverlapped ? <circle className={classes.overlapMask} fill="black" /> : null}
			</mask>

			<g mask={`url(#${maskId})`}>
				{imageUrl && !loadFailed ? (
					<foreignObject x="0" y="0" width="100%" height="100%">
						<img
							alt=""
							srcSet={imageSrcSet}
							src={imageUrl}
							style={{ borderRadius: '50%' }}
							onError={() => setLoadFailed(true)}
							className={classes.image}
						/>
					</foreignObject>
				) : (
					<>
						<circle cx="50%" cy="50%" r="50%" fill={backgroundColor} />
						<text
							x="50%"
							y="50%"
							textAnchor="middle"
							dominantBaseline="central"
							fill={textColor}
							fontWeight="bold"
							className={classnames(classes.text, isRemainingCount && classes.remainingCount)}
						>
							{text}
						</text>
					</>
				)}
			</g>

			{renderState(size, state)}
		</svg>
	);
};
