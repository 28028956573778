import classnames from 'classnames';
import React from 'react';
import { assertChildTypes } from '@web-apps/react-utils';
import { ActionElement } from '../components/ActionElement';
import { Button } from '../components/button/Button';
import { DownloadLink } from '../components/links/DownloadLink';
import { ExternalLink } from '../components/links/ExternalLink';
import { InternalLink } from '../components/links/InternalLink';
import { usePandaContext } from '../contexts/pandaContext';
import { Headline } from '../components/heading/Headline';

type GeneralProps = {
	heading: string;
	itemCount?: number;
	itemLimit?: number;
};

type Props = GeneralProps & { actionElements?: React.ReactNode[] };

const styles = {
	header: classnames(
		'cursor-default',
		'flex',
		'flex-wrap',
		'gap-8',
		'items-center',
		'pb-8',
		'md:pb-16',
		'lg:pb-24'
	),
	heading: classnames(
		'font-bold',
		'font-brand',
		'grow',
		'hyphens',
		'm-0',
		'text-gray-800',
		'text-3xl/32',
		'sm:text-4xl/36',
		'md:text-5xl/40',
		'lg:text-6xl/48'
	),
	count: classnames('font-meta', 'font-light', 'text-gray-600', 'text-viewheading-meta'),
	srCount: classnames('sr-only'),
	actionElements: classnames('flex', 'flex-wrap', 'sm:gap-16', 'gap-8'),
};

export const ViewHeader = ({ heading, itemCount, itemLimit, actionElements }: Props) => {
	const { languageKeys } = usePandaContext();

	assertChildTypes(actionElements, [
		ActionElement,
		Button,
		ExternalLink,
		InternalLink,
		DownloadLink,
	]);

	const itemCountSr =
		typeof itemLimit === 'number'
			? `${itemCount} ${languageKeys.PANDA_LISTNAV_OF} ${itemLimit}`
			: itemCount;

	return (
		<header className={styles.header}>
			<Headline className={styles.heading}>
				{heading}

				{typeof itemCount === 'number' ? (
					<>
						<span
							title={`${itemCount} ${languageKeys.PANDA_LISTNAV_TOTAL}`}
							aria-hidden="true"
							className={styles.count}
						>
							&nbsp;[{typeof itemLimit === 'number' ? `${itemCount}/${itemLimit}` : itemCount}]
						</span>

						<span className={styles.srCount}>
							{languageKeys.PANDA_LISTNAV_COUNT}: {itemCountSr}
						</span>
					</>
				) : null}
			</Headline>

			{actionElements ? <div className={styles.actionElements}>{actionElements}</div> : null}
		</header>
	);
};
