import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import classnames from 'classnames';
import { Success } from './pages/Success/Success';
import EntryPage from './pages/signup/EntryPage/EntryPage';
import Activate from './pages/Activate/Activate';
import Status from './pages/status/Status';
import Partner from './pages/partner/Partner';
import ErrorBoundary from './utils/ErrorBoundary';
import { useTranslations } from './hooks/translations';
import { PusherContext } from './components/PusherContext';
import { pusher } from './api';
import { NqModal } from './components/nqNupsi/NQNupsi';
import Tac from './pages/tac/Tac';
import { Signup } from './pages/signup/Signup';
import classes from './SignupApp.scss';
import ErrorPage from './pages/error/ErrorPage';

const UUID_PATTERN = '[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}';

const SignupApp = () => {
	const translations = useTranslations();

	if (translations.fetched) {
		return (
			<div
				className={classnames(classes.cover, {
					[classes.withBackground]: window.top === window.self,
				})}
			>
				<PusherContext.Provider value={{ client: pusher }}>
					<Router>
						<Switch>
							<ErrorBoundary>
								<Route path="/" exact component={EntryPage} />
								<Route path="/affiliate/" exact component={EntryPage} />
								<Route path={`/:identifier(${UUID_PATTERN})`} exact component={Signup} />
								<Route path="/partnerprogramm/" exact component={Signup} />
								<Route path="/partner/:partnerId" exact component={Partner} />
								<Route path="/success/:identifier" exact component={Success} />
								<Route path="/activate/:key" exact component={Activate} />
								<Route path="/status/:identifier" exact component={Status} />
								<Route path="/tac/:tacIdentifier" exact component={Tac} />
								<Route path="/error" exact component={ErrorPage} />
							</ErrorBoundary>
						</Switch>
					</Router>
					<NqModal />
				</PusherContext.Provider>
			</div>
		);
	}
	return null;
};

export default SignupApp;
