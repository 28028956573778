import classnames from 'classnames';
import React from 'react';
import { usePandaContext } from '../../../contexts/pandaContext';

import classes from './SideBarLogoHeader.module.css';
import { Headline } from '../../../components/heading/Headline';

interface Props {
	logo?: string;
	title: string;
	onChange: () => void;
}

const styles = {
	logo: classnames('object-contain', 'w-full', 'max-h-[12rem]', 'min-h-[3rem]'),
	container: classnames(
		'font-brand',
		'items-center',
		'flex',
		'flex-col',
		'gap-4',
		'p-32',
		'block',
		'relative',
		'border-b',
		'rounded-t-lg',
		'w-full',
		'bg-transparent',
		'border-gray-100',
		'radius-top-4',
		'border-dashed',
		'cursor-pointer',
		'before:content-[""]',
		'before:absolute',
		'before:w-full',
		'before:h-full',
		'before:left-0',
		'before:top-0',
		'after:rounded-t-lg',
		'hover:before:bg-black',
		'hover:before:opacity-50',
		'focus-visible:before:bg-black',
		'focus-visible:before:opacity-50',
		'focus-visible:after:ring',
		'focus-visible:after:ring-inset',
		'focus-visible:after:ring-color-focus',
		'select-none',
		classes.logo
	),
	company: (hasLogo: boolean) =>
		classnames('font-bold', 'text-gray-800', 'text-xl/24', hasLogo && 'sr-only'),
	label: (hasLogo: boolean) => classnames('text-gray-600', 'text-base/20', hasLogo && 'sr-only'),
};

export const SideBarLogoHeader = (props: Props) => {
	const { languageKeys } = usePandaContext();
	const labelId = React.useId();

	return (
		<>
			<Headline screenreaderOnly>{props.title}</Headline>
			<button
				type="button"
				className={styles.container}
				onClick={props.onChange}
				aria-labelledby={labelId}
			>
				{props.logo ? <img className={styles.logo} src={props.logo} alt="" /> : null}

				<span className={styles.company(!!props.logo)}>{props.title}</span>
				<span id={labelId} className={styles.label(!!props.logo)}>
					{props.logo ? languageKeys.PANDA_CHANGE_LOGO : languageKeys.PANDA_ADD_LOGO}
				</span>
			</button>
		</>
	);
};
